import React, { Component } from "react";
import { navigate } from "gatsby";
import _ from "lodash";
import {
	FacebookShareButton, FacebookIcon, TwitterShareButton, TwitterIcon, LinkedinShareButton, LinkedinIcon, EmailShareButton, EmailIcon
} from 'react-share';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { CopyToClipboard } from "react-copy-to-clipboard";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronUp, faTimes, faShareAlt, faLink, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { faFacebookF, faTwitter, faLinkedinIn } from '@fortawesome/free-brands-svg-icons';

class SocialShare extends Component {
	state = {
		copied: false,
		isShareOpen: false,
	};

	componentDidMount = () => {
		console.log('change')
	}

	handleShareOpen = () => {
		if (this.state.isShareOpen) {
			this.setState({
				isShareOpen: false,
			});
		} else {
			this.setState({
				isShareOpen: true,
			});
		}
	};

	handleCopyLink = () => {
		this.setState({ copied: true });

		setTimeout(() => {
			this.setState({ copied: false });
		}, 3000);
	};

	handleScrollToTop = () => {
		if (this.props.isHome) {
			window.location.href = '/#pre-1800';
		} else {
			window.scrollTo({
				top: 0,
				behavior: "smooth",
			});
		}
	}

	handleSocialShare = (link) => {
		window.open(link, 'social-share', 'left=20,top=20,width=500,height=500,toolbar=1,resizable=0');
		return false;
	}

	render() {
		const { url, title } = this.props.dataOptions;

		return (
			<div className="social-share-container d-print-none">
				<div className={`social-share-buttons ${this.state.isShareOpen ? "open-share" : "close-share"}`}>
					<OverlayTrigger
						placement="left"
						delay={{ show: 0, hide: 900 }}
						overlay={<Tooltip>Link Copied.</Tooltip>}
						trigger="click"
						show={this.state.copied}
					>
						<CopyToClipboard text={url} onCopy={this.handleCopyLink}>
							<button type="button" className="share-button">
								<FontAwesomeIcon icon={faLink} size="sm" />
							</button>
						</CopyToClipboard>
					</OverlayTrigger>

					<FacebookShareButton url={url} quote={title} className="share-button">
						<FacebookIcon size={32} round iconFillColor="black" bgStyle={{ fill: "white" }} />
					</FacebookShareButton>

					<TwitterShareButton url={url} title={title} className="share-button">
						<TwitterIcon size={32} round iconFillColor="black" bgStyle={{ fill: "white" }} />
					</TwitterShareButton>

					<LinkedinShareButton url={url} title={title} source={title} className="share-button">
						<LinkedinIcon size={32} round iconFillColor="black" bgStyle={{ fill: "white" }} />
					</LinkedinShareButton>

					<EmailShareButton
						url={url}
						className="share-button"
						subject={title}
						body={`An interactive timeline that complements the series British Columbia: An Untold History through diverse, racialized and marginalized histories from across the geographical regions of B.C. Check out ${title}:`}
					>
						<EmailIcon size={32} round iconFillColor="black" bgStyle={{ fill: "white" }} />
					</EmailShareButton>

				</div>
				<div className="share-toggle-button">
					<button
						className={`share-copy-button share-button ${this.state.isShareOpen ? "active" : ""}`}
						type="button"
						onClick={() => this.handleShareOpen()}
					>
						<FontAwesomeIcon icon={this.state.isShareOpen ? faTimes : faShareAlt} size="lg" color={this.state.isShareOpen ? 'white' : 'black'} className="align-middle" />
					</button>
				</div>
				<div id="back-to-top">
					<button type="button" className="share-button" onClick={this.handleScrollToTop}>
						<FontAwesomeIcon icon={faChevronUp} size="lg" />
					</button>
				</div>
			</div>
		);
	}
}

export default SocialShare;

import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import _ from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faMinus } from '@fortawesome/free-solid-svg-icons';

export default function Decade({ filterBtn, uncheckItem }) {
  // const decades = (allDecades) => {
  //   allDecades.sort((a, b) => {
  //     if (a.value.indexOf('Pre-') !== -1) return -1;
  //     if (b.value.indexOf('Pre-') !== -1) return 1;

  //     return parseInt(a) - parseInt(b);
  //   });
  // }


  return (
    <StaticQuery
      query={graphql`
				query {
					allDecades {
						nodes {
							value
						}
					}
				}
			`}
      render={(data) => (
        <div className="panel panel-default">
          <div className="panel-heading" id="decadeHeading">
            <h4 className="panel-title mb-0">
              <a
                href="#decade"
                className="site-label"
                data-toggle="collapse"
                data-target="#decade"
                aria-expanded="false"
                aria-controls="decade"
                role="button"
                tabIndex="-1"
              >
                <span>Decade {uncheckItem.length > 0 && <span>({uncheckItem.length})</span>}</span>
                <span className="collapse-icon">
                  <FontAwesomeIcon icon={faPlus} className="show" size="sm" />
                  <FontAwesomeIcon icon={faMinus} className="hide" size="sm" />
                </span>
              </a>
            </h4>
          </div>
          <div id="decade" className="panel-collapse collapse" aria-labelledby="decadeHeading" data-parent="#accordion">
            <div className="panel-body">
              {data.allDecades.nodes.sort((a, b) => {
                if (a.value.indexOf('Pre-') !== -1) return -1;
                if (b.value.indexOf('Pre-') !== -1) return 1;

                return parseInt(a) - parseInt(b);
              }).map((item, index) => (
                <label key={index} className="checkbox-inline" htmlFor={`decade-${_.kebabCase(item.value)}`}>
                  <input
                    type="checkbox"
                    id={`decade-${_.kebabCase(item.value)}`}
                    name="decade"
                    value={item.value}
                    onChange={filterBtn}
                    checked={!!(_.isArray(uncheckItem) && uncheckItem.indexOf(item.value) !== -1)}
                  />

                  <span>{item.value}</span>
                </label>
              ))}
            </div>
          </div>
        </div>
      )}
    />
  );
}

import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import _ from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faMinus } from '@fortawesome/free-solid-svg-icons';

export default function GeoArea({ filterBtn, uncheckItem }) {
  return (
    <StaticQuery
      query={graphql`
				query {
					allGeoAreas {
						nodes {
							value
						}
					}
				}
			`}
      render={(data) => (
        <div className="panel panel-default">
          <div className="panel-heading" id="geoareaHeading">
            <h4 className="panel-title mb-0">
              <a
                href="#geographic_area"
                className="site-label"
                data-toggle="collapse"
                data-target="#geographic_area"
                aria-expanded="false"
                aria-controls="geographic_area"
                role="button"
                tabIndex="-1"
              >
                <span>Geographical Area {uncheckItem.length > 0 && <span>({uncheckItem.length})</span>}</span>
                <span className="collapse-icon">
                  <FontAwesomeIcon icon={faPlus} className="show" size="sm" />
                  <FontAwesomeIcon icon={faMinus} className="hide" size="sm" />
                </span>
              </a>
            </h4>
          </div>
          <div
            id="geographic_area"
            className="panel-collapse collapse"
            aria-labelledby="geoareaHeading"
            data-parent="#accordion"
          >
            <div className="panel-body">
              {data.allGeoAreas.nodes.map((item, index) => (
                <label key={index} className="checkbox-inline" htmlFor={`geo-${_.kebabCase(item.value)}`}>
                  <input
                    type="checkbox"
                    name="geographic_area"
                    id={`geo-${_.kebabCase(item.value)}`}
                    value={item.value}
                    onChange={filterBtn}
                    checked={!!(_.isArray(uncheckItem) && uncheckItem.indexOf(item.value) !== -1)}
                  />
                  <span>{item.value}</span>
                </label>
              ))}
            </div>
          </div>
        </div>
      )}
    />
  );
}

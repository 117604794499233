/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { Container, Row, Col } from 'react-bootstrap';
import Header from './header';


const Layout = ({ children, isSearchPage, classContainer, noBackLink, bgContainer, location }) => {
  const data = useStaticQuery(graphql`
		query SiteTitleQuery {
			site {
				siteMetadata {
					title
					description
				}
			}
		}
	`);

  const bgImg = bgContainer && { backgroundImage: `url(${bgContainer})` };

  return (
    <>

      <div className="main-site">
        <a className="skip-link screen-reader-text" href="#primary">
          Skip to the content
        </a>

        <Header
          siteTitle={data.site.siteMetadata.title}
          siteDescription={data.site.siteMetadata.description}
          isSearchPage={isSearchPage}
          noBackLink={noBackLink}
          showHeaderinLoader={true}
        />

        <main id="primary" className={`${classContainer ? classContainer : ''} main-container`} style={bgImg}>
          {children && children}
        </main>
      </div>
    </>
  );
};

export default Layout;

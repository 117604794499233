import React, { useState, useEffect } from 'react';
import { Link, navigate } from 'gatsby';
import { Col, Container, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import Logo from '../../static/kn-blue-bcauh-logo.svg';
import Filter from './filter';


const GoBack = () => {
  return (
    <a href="#" onClick={() => window.history.back()} className="m-back-link d-block d-md-none">
      <FontAwesomeIcon icon={faChevronLeft} />
    </a>
  );
};

const Header = ({ siteTitle, isSearchPage, noBackLink }) => {

  return (
    <header id="site-header" className={`site-header fixed-top compensate-for-scrollbar `} role="banner">
      <Container fluid="lg">
        <Row className="align-items-center">
          <Col lg={3} md={3}>
            <div className="site-header-logo">
              {!noBackLink && <GoBack />}
              <a href="/">
                <img
                  src={Logo}
                  title={siteTitle}
                  alt={`${siteTitle} Logo`}
                  className="site-logo"
                  width="220"
                  height="50"
                />
              </a>

            </div>
          </Col>
          {!isSearchPage && (
            <Col>
              <Filter />
            </Col>
          )}
        </Row>
      </Container>
    </header>
  );
}

export default Header;

import React, { Component } from "react";
// import { useHistory } from "react-router-dom"
// import { navigate } from "gatsby"
import _ from "lodash";
import queryString from "query-string";
import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter, faTimes, faMinus } from '@fortawesome/free-solid-svg-icons';
import DecadeFilter from "./filter/decade";
import HeritageFilter from "./filter/heritage";
import GeoAreaFilter from "./filter/geoarea";
import MediaTypeFilter from "./filter/media";
// import StoryStreamFilter from "./filter/stream";
import { navigate } from "gatsby-link"

const initialState = {
	decade: [],
	geographic_area: [],
	media_type: [],
	heritage: [],
	story_stream: [],
};

class Filter extends Component {
	state = {
		decade: [],
		geographic_area: [],
		media_type: [],
		heritage: [],
		story_stream: [],
		toggleFilter: false,
	};

	componentDidMount() {
		// if there are already filters selected
		this.setState(this.props.checkedItems);
	}

	componentDidUpdate() {

	}

	handleCheckboxInput = (event) => {
		const { name, value, checked } = event.target;

		if (checked) {
			// store selected values
			this.setState((prevState) => ({
				[name]: prevState[name].concat([value]),
			}));
		} else {
			// remove unselected value
			this.setState((prevState) => ({
				[name]: prevState[name].filter((val) => val !== value),
			}));
		}
	};

	handleSubmit = (event) => {
		event.preventDefault();

		// remove empty states
		const formValues = _.omitBy(this.state, _.isEmpty);

		// url params
		const searchQuery = queryString.stringify(formValues, {
			arrayFormat: "comma",
		});

		// generate new search query URL
		const searchQueryUrl = searchQuery ? `/search?${searchQuery}` : "/search";

		// redirect to search with the selected values
		// navigate(searchQueryUrl);
		window.location.assign(searchQueryUrl);
		// window.setTimeout(() => window.scrollTo(0, 0), 3000);
	};

	handleReset = (event) => {
		event.preventDefault();

		// reset the states
		this.setState(initialState);
	};

	deleteSelectedItem = (event) => {
		const { name, value, checked } = event.target;

		// uncheck deleted filter
		if (!checked) {
			this.setState((prevState) => ({
				[name]: prevState[name].filter((val) => val !== value),
			}));
		}
	};

	openFilter = (event) => {
		event.preventDefault();

		this.setState({
			toggleFilter: true,
		});
	};

	closeFilter = (event) => {
		event.preventDefault();

		this.setState({
			toggleFilter: false,
		});
	};

	handleCancelFilter = (event) => {
		event.preventDefault();

		// close the filter
		this.setState({
			toggleFilter: false,
		});

		// reset the states
		this.setState(initialState);
	};

	render() {
		const selectedData = this.state;

		const filterKeys = Object.keys(selectedData);

		// ignore 'toggleFilter' on states
		if (filterKeys.indexOf("toggleFilter") > -1) {
			filterKeys.splice(filterKeys.indexOf("toggleFilter"), 1);
		}

		const {
			decade, geographic_area, media_type, heritage, story_stream,
		} = selectedData;


		return (
			<>
				<button type="button" className="filter-toggle-btn d-print-none" onClick={this.openFilter} tabIndex="0">
					<span className="icon"><FontAwesomeIcon icon={faFilter} size="sm" className="align-baseline" /></span>
					<span className="site-label d-none d-sm-block">Filter</span>
				</button>
				<div className={`filter-container d-print-none ${this.state.toggleFilter ? "is-open" : ""}`}>
					<form className="filter-wrapper" onSubmit={this.handleSubmit}>
						<div className="filter-content scrollable-panel">
							<div className="panel-header">
								<div className="panel-heading-wrapper">
									<p className="site-label mb-0">Filter Content By:</p>
									<button type="button" className="close-filter" onClick={this.closeFilter} tabIndex="-1">
										<FontAwesomeIcon icon={faTimes} size="sm" className="align-baseline" />
									</button>
								</div>
								{selectedData && (
									<div className="filter-selected-values">
										{filterKeys.map((key) => selectedData[key].map((val, index) => (
											<label key={index} className="remove-icon" htmlFor={val}>
												<input
													type="checkbox"
													id={val}
													name={key}
													value={val}
													onChange={this.deleteSelectedItem}
													checked
												/>
												{val}
												<FontAwesomeIcon icon={faMinus} size="sm" className="align-top icon" />
											</label>
										)))}
									</div>
								)}
							</div>

							<div className="panel-group" id="accordion">
								<DecadeFilter filterBtn={this.handleCheckboxInput} uncheckItem={decade} />
								<HeritageFilter filterBtn={this.handleCheckboxInput} uncheckItem={heritage} />
								<GeoAreaFilter filterBtn={this.handleCheckboxInput} uncheckItem={geographic_area} />
								<MediaTypeFilter filterBtn={this.handleCheckboxInput} uncheckItem={media_type} />
							</div>
						</div>

						<div className="filter-button-group">
							<Button tabIndex="-1" variant="dark" type="submit" block>
								<span>Apply</span>
								<span className="icon"><FontAwesomeIcon icon={faFilter} size="xs" className="align-baseline" /></span>
							</Button>
							<Button variant="outline-dark" type="reset" onClick={this.handleReset} tabIndex="-1">
								Reset
							</Button>
							<Button tabIndex="-1" variant="outline-dark" onClick={this.handleCancelFilter}>
								Cancel
							</Button>
						</div>
					</form>
				</div>
			</>
		);
	}
}

export default Filter;

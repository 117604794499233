import React from 'react';
import { Helmet } from 'react-helmet';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import DefaultImg from '../../static/landing-page-bg.jpg';

const Seo = ({ meta, title, description, image, url, story, theme_color, keywords }) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            url
            keywords
            site_image
          }
        }
      }
    `
  )

  const metaDescription = description || site.siteMetadata.description
  const defaultTitle = site.siteMetadata?.title
  const metaUrl = url ? url : site.siteMetadata.url
  const metaImg = `${image}?ver=${_.now()}` || `${site.siteMetadata.url}${DefaultImg}?ver=${_.now()}`

  return (
    <Helmet
      htmlAttributes={{
        lang: 'en',
      }}
      title={title}
      link={
        metaUrl
          ? [
            {
              rel: "canonical",
              href: metaUrl,
            },
          ]
          : []
      }
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:type`,
          content: story ? `story` : `website`,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          name: `theme-color`,
          content: theme_color,
        },
        {
          name: `keywords`,
          content: keywords,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ].concat(
        metaImg ? [
          {
            property: `og:image`,
            content: metaImg,
          },
          {
            name: `twitter:card`,
            content: `summary_large_image`
          }
        ] : [{
          name: "twitter:card",
          content: "summary",
        }]
      ).concat(meta)}
    />
  )
}

Seo.defaultProps = {
  meta: [],
  description: ``,
  title: ``,
  url: ``,
  keywords: ``,
  site_image: ``,
  theme_color: `#f8f8f8`,
}

Seo.propTypes = {
  meta: PropTypes.arrayOf(PropTypes.object),
  description: PropTypes.string,
  title: PropTypes.string.isRequired,
  url: PropTypes.string,
  keywords: PropTypes.string,
  site_image: PropTypes.string,
  theme_color: PropTypes.string,
}

export default Seo